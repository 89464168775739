import { gql } from "@apollo/client";
import { EventCard } from "components/EventCard";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { FlexibleContentBlocksEventList } from "graphql";

interface EventListProps extends FlexibleContentBlocksEventList {
  className?: string;
}

const EventList = ({ className, eventItems: items, id }: EventListProps) => {
  const hasItems = items && items.nodes.length > 0;

  return (
    <div className={`py-6 px-4 md:px-0`} id={id ?? ``}>
      {hasItems && (
        <div className={`container mx-auto max-w-7xl gap-4 flex flex-col`}>
          {items.nodes.map((item, i) => {
            return <EventCard key={item?.id} {...(item as any)} />;
          })}
        </div>
      )}
    </div>
  );
};

export default EventList;

EventList.fragments = {
  entry: gql`
    fragment EventListFragment on FlexibleContentBlocksEventList {
      __typename
      id
      eventItems {
        nodes {
          ... on Event {
            id
            title
            content
            featuredImage {
              node {
                ...MediaItemFragment
              }
            }
            eventOptions {
              date
              dateRange {
                start
                end
              }
              useJustMonth
              isMultipleDays
              links {
                type
                linkType
                pdfFile {
                  node {
                    mediaItemUrl
                    title
                  }
                }
                link {
                  target
                  title
                  url
                }
              }
              iconLink {
                target
                title
                url
              }
              location {
                city
                country
                placeId
                state
                stateShort
                postCode
                streetAddress
                streetName
              }
            }
          }
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
