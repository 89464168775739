import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { Event, EventOptionsLinks_Fields } from "graphql";
import { Balancer } from "react-wrap-balancer";
import removeAmp from "utilities/removeAmp";
import { BiMap } from "react-icons/bi";
interface EventCard extends Event {
  className?: string;
}

const EventCard = ({
  className,
  title,
  content,
  featuredImage,
  eventOptions,
  id,
}: EventCard) => {
  const { date, dateRange, useJustMonth, isMultipleDays, links, location } =
    eventOptions || {};
  const {
    streetAddress,
    city,
    state,
    postCode,
    placeId,
    streetName,
    stateShort,
  } = location || {};
  // using placeId to create google map link
  let googleLink =
    location && `https://www.google.com/maps/place/?q=place_id:${placeId}`;
  const hasAddress = streetAddress || city || state || postCode;

  const { start, end } = dateRange || {};
  // Format date from 09/23/2021 to September 23, 2021
  const formattedDate = !useJustMonth && date
    ? new Date(date).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : null;

    const fomattedJustMonth = useJustMonth && date ? new Date(date).toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    }) : null;

  const hasDateRange = start && end;
  // Determing Month from start date and end date and display as October 2023
  const formattedDateRangeStart = hasDateRange
    ? `${new Date(start).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`
    : null;

    const formattedDateRangeEnd = hasDateRange
    ? `${new Date(end).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`
    : null;

  return (
    <div className={`flex flex-col md:flex-row md:gap-4 px-4 md:px-0 z-20`}>
      <div className={`flex flex-col w-full md:w-1/3`}>
        <FeaturedImage
          className={`w-full h-auto`}
          imgClassName="w-full h-auto"
          image={featuredImage?.node}
        />
      </div>
      <div
        className={`flex flex-col md:w-2/3 bg-brand-gray px-4 md:px-8 py-4 gap-4 relative`}
      >
        <div className={`w-fit border-b-[1px] border-primary/30 py-2`}>
          {date && !useJustMonth && !isMultipleDays && (
            <p className={`text-sm font-sans uppercase text-primary`}>
              {formattedDate}
            </p>
          )}
          {date && useJustMonth&& !isMultipleDays && (
            <p className={`text-sm font-sans uppercase text-primary`}>
              {fomattedJustMonth}
            </p>
          )}
          {hasDateRange && isMultipleDays && (
            <p className={`text-sm font-sans uppercase text-primary`}>
              {formattedDateRangeStart} - {formattedDateRangeEnd}
            </p>
          )}
        </div>
        {title && (
          <h2 className={`text-2xl font-serif max-w-md text-primary`}>
            <Balancer>{title}</Balancer>
          </h2>
        )}
        {content && (
          <div
            className={`text-base font-sans text-primary flex flex-col gap-2`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {links && (
          <div
            className={`flex flex-col lg:flex-row gap-4 relative lg:absolute right-8 top-4 mb-6 lg:mb-0 ml-6 lg:ml-0`}
          >
            {hasAddress && (
              <a
                href={googleLink ?? "#"}
                className={`text-sm font-sans uppercase text-primary mx-auto flex flex-row gap-2`}
              >
                <span className={`h-fit`}>
                  <BiMap className={`text-primary w-6 h-auto`} />
                </span>
                <span className={`flex flex-col`}>
                  <span>{streetName && `${streetName}`}</span>
                  <span>
                    {city && `${city}, `} {stateShort && `${stateShort} `}{" "}
                    {postCode && `${postCode}`}
                  </span>
                </span>
              </a>
            )}
            <div className={`flex flex-row lg:flex-col gap-2 mx-auto`}>
              {links.map((item, i) => {
                const { type, link, pdfFile, linkType } = item ?? {
                  type: ["primary"],
                };

                if (linkType) {
                  return (
                    <Button
                      // @ts-expect-error
                      variant={type[0]}
                      key={`${id}-link-${i}`}
                      href={pdfFile?.node?.mediaItemUrl ?? "#"}
                      className={`w-fit`}
                    >
                      {removeAmp(pdfFile?.node?.title ?? ``)}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                    // @ts-expect-error
                      variant={type[0]}
                      key={`${id}-link-${i}`}
                      href={link?.url ?? "#"}
                      className={`w-fit`}
                    >
                      {removeAmp(link?.title ?? ``)}
                    </Button>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
